<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";


/**
 * Advanced table component
 */
export default {
  page: {
    title: "Drivers",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Drivers",
      loader: true,
      items: [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Drivers",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      filterStatus: ['reset', 'pending-approval', 'in-complete', 'verified'],
      filterData: 'reset',
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        { key: "createdAt", label: "Date", sortable: true },
        { key: "first_name", label: "First Name", sortable: true },
        { key: "last_name", label: "Last Name", sortable: true },
        { key: "email_address", label: "Email", sortable: true },
        { key: "phone_number", label: "Phone", sortable: true },
        { key: "state", label: "State", sortable: true },
        { key: "device_type", label: "Device type", sortable: true },
        { key: `status.account_status`, label: "Driver status", sortable: true },
        { key: `status.in_ride`, label: "In Ride", sortable: true },
        { key: "action" }
      ],

      data_fields: {
        "First Name": "first_name",
        "Last Name": "last_name",
        "Phone Number": "phone_number",
        "State": "state",
        "Email Address": "email_address",
        "Device Type": "device_type"
      },

      deleteDriverId: null
    };
  },

  watch: {
    filterData: function (val) {
      this.$store.dispatch('driver/setRequiredDrivers', val)
    },

    rows: function (val) {
      this.totalRows = val
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    drivers() {
      return this.$store.getters['driver/driversGetter'];
    },
    rows() {
      return this.drivers.length;
    }

  },

  mounted() {
    this.$store.dispatch('driver/getDrivers')

    // Set the initial number of items
    this.totalRows = this.drivers.length;


  },

  methods: {
    /**
     * Search the table data with search input
     */

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    inRideStatus(status) {
      if (status === true) {
        return 'Yes'
      }
      else {
        return 'No'
      }
    },

    convertDate(date) {
      if (date) {
        let newDate = new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
        return newDate
      }
    },
    deleteDriver(){
      this.$store.dispatch('driver/deleteDriver',this.deleteDriverId)
    }

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <h4 class="card-title font-size-20"><strong> All Drivers</strong></h4>
              <download-excel :data="drivers" :fields="data_fields">
                <b-button variant="primary">Export Drivers</b-button>
              </download-excel>
            </div>
            <div class="row mt-4">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Filter&nbsp;
                    <b-form-select v-model="filterData" size="sm" :options="filterStatus"></b-form-select>&nbsp;
                  </label>
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-2 col-lg-3 col-xl-2 d-inline-flex"
                style="font-weight: 600;margin-top: 4px;">
                No. of Drivers: {{ totalRows }}
              </div>

              <!-- Search -->
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="drivers" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                @filtered="onFiltered">

                <template v-slot:cell(createdAt)="date">
                  {{ convertDate(date.value) }}
                </template>

                <template v-slot:cell(status.account_status)="row">
                  <div class="badge font-size-12"
                    :class="{ 'badge-danger': `${row.value}` === 'in-complete', 'badge-success': `${row.value}` === 'verified', 'badge-warning': `${row.value}` === 'pending-approval' }">
                    {{ row.value }}
                  </div>
                </template>
                <template v-slot:cell(status.in_ride)="row1">
                  <div class="badge font-size-12"
                    :class="{ 'badge-danger': `${row1.value}` === 'false', 'badge-success': `${row1.value}` === 'true' }">
                    {{ inRideStatus(row1.value) }}
                  </div>
                </template>

                <template v-slot:cell(action)="{ item }">
                  <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover data-toggle="tooltip"
                    title="Details" @click="$router.push({ path: `/drivers/${item._id}` })">
                    <i class="ri-folder-shared-fill font-size-24"></i>
                  </a>
                  <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover data-toggle="tooltip"
                    title="Documents" @click="$router.push({ path: `/drivers/document/${item._id}` })">
                    <i class="ri-file-fill font-size-24"></i>
                  </a>
                  <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover data-toggle="tooltip"
                    title="Rides" @click="$router.push({ path: `/drivers/driver-rides/${item._id}` })">
                    <i class="ri-taxi-wifi-fill font-size-24"></i>
                  </a>

                  <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover v-b-modal.delete-driver
                    @click="deleteDriverId = item._id" data-toggle="tooltip" title="Delete">
                    <i class="ri-delete-bin-6-fill font-size-24"></i>
                  </a>


                </template>



              </b-table>
            </div>
            <div class="row " v-show="drivers.length === 0">
              <div class="col text-center">
                <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
              </div>
            </div>

            <b-modal id="delete-driver" title="Delete driver" title-class="font-18" @ok="deleteDriver()" ok-title="Delete">
              <p>Are you sure?</p>
            </b-modal>

            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
